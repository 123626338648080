import { SerializedError } from '@reduxjs/toolkit'
import { isString } from 'lodash/fp'
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { StatusCode, getErrorByStatusCode } from './getErrorByStatusCode'

export type ErrorT =
  | string
  | FetchBaseQueryError
  | SerializedError
  | QueryFulfilledRejectionReason<BaseQueryFn>

export const getErrorMsg = (error?: ErrorT) => {
  if (!error) return getErrorByStatusCode(500).description
  if (isString(error)) return error
  if ('data' in error && error.data) return error.data as string
  if ('isUnhandledError' in error && !error.isUnhandledError) {
    const { data, error: localError } = (error as { error?: unknown })
      ?.error as {
      error?: string
      data?: string
    }
    return data || localError || getErrorByStatusCode(500).description
  }
  if ('message' in error && error.message) return error.message
  if ('error' in error) return error.error as string
  if ('status' in error)
    return getErrorByStatusCode(error.status as StatusCode).description
}
