import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { Instance, UploadType } from 'openapi'
import { rootReducer } from 'store'

export type InstanceInfoState = {
  data: Instance | null
  isPending: boolean
  error?: unknown
}

export type ExtensionsMap = {
  [key in UploadType]: string[] | undefined
}

const selectState = (state: InstanceInfoState) => state

const selectInstanceInfo = createSelector(selectState, (state) => state.data)

const selectInstanceInfoError = createSelector(
  selectState,
  (state) => state.error,
)
const selectInstanceInfoPending = createSelector(
  selectState,
  (state) => state.isPending,
)

const selectConfiguration = createSelector(
  selectInstanceInfo,
  (data) => data?.configuration,
)

const selectSupportedExtensions = createSelector(
  selectConfiguration,
  (config) =>
    ({
      IMAGE: config?.media_attachments.image_extensions,
      VIDEO: config?.media_attachments.video_extensions,
      AUDIO: config?.media_attachments.audio_extensions,
      DOCUMENT: config?.media_attachments.doc_extensions,
    }) as unknown as ExtensionsMap,
)

const selectSupportedExtensionsFor = createSelector(
  [selectSupportedExtensions, (_, type: UploadType) => type],
  (map, type) => map[type],
)

const selectFullExtensionsList = createSelector(
  selectSupportedExtensions,
  (ext) =>
    Object.keys(ext).reduce((acc, value) => {
      const subList = ext[value as UploadType] || []

      return [...acc, ...subList]
    }, [] as string[]),
)

const selectAttachmentsLimit = createSelector(
  selectConfiguration,
  (config) => config?.posts.max_media_attachments || 0,
)

const selectPostSizeLimit = createSelector(
  selectConfiguration,
  (config) => config?.posts.max_characters || 0,
)

const selectPlatformRules = createSelector(
  selectInstanceInfo,
  (data) => data?.rules,
)

const initialState: InstanceInfoState = {
  data: null,
  isPending: false,
}

export const instanceInfoSlice = createSlice({
  name: 'instanceInfo',
  initialState,
  reducers: {
    setInfo: (state, action: PayloadAction<Instance>) => {
      state.data = action.payload
    },
    setError: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
  },
  selectors: {
    selectInstanceInfo,
    selectInstanceInfoError,
    selectInstanceInfoPending,
    selectPostSizeLimit,
    selectConfiguration,
    selectAttachmentsLimit,
    selectSupportedExtensions,
    selectFullExtensionsList,
    selectPlatformRules,
    selectSupportedExtensionsFor,
  },
}).injectInto(rootReducer)
