import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { userSlice } from 'user/userSlice'
import { setUser } from '../client'

export const useSentryUser = () => {
  const account = useSelector(userSlice.selectors.selectAccount)
  const currentUser = useSelector(userSlice.selectors.selectCurrentUser)

  const { email } = account || {}
  const { username, id: userId } = currentUser || {}

  useEffect(() => {
    email && username && userId && setUser({ email, username, userId })
  }, [email, username, userId])
}
