import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { getErrorMsg } from 'error-kit/helpers/getErrorMsg'

import { toast } from 'react-toastify'

export type ApiError = {
  code: string
  message?: string
}

export const handleBasicResponse = async <T extends Promise<unknown>>(
  queryFulfilled: T,
  successMsg?: string,
) => {
  try {
    await queryFulfilled
    successMsg && toast.success(successMsg)
    return queryFulfilled
  } catch (e) {
    toast.error(getErrorMsg(e as FetchBaseQueryError))
  }
}
