import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RegisterInput } from './components/signup/SignupForm/hooks/useForm'

export type AuthState = {
  signupStep: 1 | 2 | 3 | 4
  signupFields: RegisterInput | null
  token: string | null
  isPending: boolean
  isAuthenticated: boolean
}
export const AUTH_COOKIE_NAME = 'auth_token'

const initialState: AuthState = {
  signupStep: 1,
  token: null,
  signupFields: null,
  isPending: false,
  isAuthenticated: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    setSignupStep: (state, action: PayloadAction<1 | 2 | 3 | 4>) => {
      state.signupStep = action.payload
    },
    setSignupFields: (state, action: PayloadAction<RegisterInput | null>) => {
      state.signupFields = action.payload
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
    login: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true
      state.token = action.payload
      state.isPending = false
    },
  },
  selectors: {
    selectToken: (state) => state.token,
    selectSignupStep: (state) => state.signupStep,
    selectSignupFields: (state) => state.signupFields,
    selectPending: (state) => state.isPending,
    selectIsAuthenticated: (state) => state.isAuthenticated,
  },
})
