import { combineSlices, configureStore } from '@reduxjs/toolkit'
import { api } from 'openapi/api'
import { authSlice } from 'auth/authSlice'
import { userSlice } from 'user/userSlice'
import subscribeActionMiddleware from 'redux-subscribe-action'

export const rootReducer = combineSlices(api, authSlice, userSlice)

export const createStore = (preloadedState?: Partial<RootState>) =>
  configureStore({
    devTools: {
      name: process.env.APP_NAME || 'wellesley',
    },
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, subscribeActionMiddleware),
  })

let store: ReturnType<typeof createStore>
export const getStore = (preloadedState?: Partial<RootState>) => {
  if (store) return store

  store = createStore(preloadedState)
  return store
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof createStore>
