import { FC } from 'react'
import { logger } from 'helpers/logger'
import ooopsIcon from './ooopsIcon.svg'
import { getErrorMsg } from '../helpers/getErrorMsg'
import {
  ErrorBody,
  getErrorByStatusCode,
  isErrorBody,
  isStatusError,
} from '../helpers/getErrorByStatusCode'
import { Links } from './Links'
import { useRoute404Refresh } from './useRoute404Refresh'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export type ErrorType = 'user' | 'post' | 'page'

type ErrorPageProps = {
  error: unknown
}

const getError = (error: unknown) => {
  switch (true) {
    case isErrorBody(error):
      return error
    case isStatusError(error): {
      error === 500 && logger.error('ErrorPage: ', error)
      return getErrorByStatusCode(error)
    }
    case error && !!getErrorMsg(error):
      return { title: getErrorMsg(error) } as ErrorBody
    default: {
      logger.error('ErrorPage: ', error)
      return getErrorByStatusCode(500)
    }
  }
}

export const ErrorPage: FC<ErrorPageProps> = ({ error }) => {
  useRoute404Refresh((error as FetchBaseQueryError)?.data as string)

  const { title, description, links } = getError(error)

  console.trace('Error', error)

  return (
    <div className="relative flex min-h-[100vh] items-center justify-center">
      <div className="absolute max-w-[580px] translate-y-[-10%]">
        <img className="mb-3" src={ooopsIcon} />
        {title && (
          <h1 className="mb-[8px] text-[24px] font-bold leading-normal text-slate-700">
            {title}
          </h1>
        )}
        {description && (
          <p className="mb-[8px] text-[20px]  leading-normal text-slate-700">
            {description}
          </p>
        )}
        {links?.length ? <Links linksList={links} /> : null}
      </div>
    </div>
  )
}
