import { authSlice } from 'auth/authSlice'
import { useSelector } from 'react-redux'
import { useGetApiV1NotificationsCountQuery } from '../../api'
import { NotificationStatus } from 'openapi'

type HookProps = {
  status?: NotificationStatus
}

const useGetNotificationsCount = ({ status }: HookProps) => {
  const isLogged = useSelector(authSlice.selectors.selectIsAuthenticated)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useGetApiV1NotificationsCountQuery(
    {
      ...(status && { status }),
    },
    { skip: !isLogged },
  )
}

export default useGetNotificationsCount
