import { toast } from 'react-toastify'
import { api } from 'openapi/api'
import { NotificationStatus, handleBasicResponse } from 'openapi'

import { notificationsSlice } from './notificationsSlice'

export const notificationsApi = api.enhanceEndpoints({
  endpoints: {
    getApiV1Notifications: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(notificationsSlice.actions.setPending(true))
        try {
          const response = await handleBasicResponse(queryFulfilled)
          response &&
            dispatch(notificationsSlice.actions.setNotifications(response.data))
        } catch (e) {
          dispatch(notificationsSlice.actions.setError(e))
        } finally {
          dispatch(notificationsSlice.actions.setPending(false))
        }
      },
    },
    postApiV1NotificationsByIdMark: {
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await handleBasicResponse(queryFulfilled)
          dispatch(notificationsSlice.actions.markNotification(args))
        } catch (e) {
          toast.error(`Mark notification error', ${e as string}`)
        }
      },
    },
    postApiV1NotificationsMarkAll: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await handleBasicResponse(queryFulfilled)
        dispatch(notificationsSlice.actions.setUnreadCount(0))
      },
    },
    getApiV1NotificationsCount: {
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response = (await handleBasicResponse(
            queryFulfilled,
          )) as unknown as { data: number }

          const isTotal = !(args as { status: NotificationStatus }).status

          if (response) {
            //todo: tslint type issues because of api response, clean when ready
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            dispatch(
              isTotal
                ? notificationsSlice.actions.setTotalCount(response.data)
                : notificationsSlice.actions.setUnreadCount(response.data),
            )
          }
        } catch (e) {
          toast.error(
            `Error getting unread notifications count', ${e as string}`,
          )
        }
      },
    },
  },
})

export const {
  useGetApiV1NotificationsQuery,
  usePostApiV1NotificationsByIdMarkMutation,
  usePostApiV1NotificationsMarkAllMutation,
  useGetApiV1NotificationsCountQuery,
} = notificationsApi
