import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { Notification, NotificationStatus } from 'openapi'
import { rootReducer } from 'store'

export type NotificationsSliceState = {
  data: Notification[] | null
  count: {
    total?: number
    unread?: number
  }
  isPending: boolean
  error?: unknown
}

type MarkNotificationProps = {
  id: string
  status: NotificationStatus
}

const initialState: NotificationsSliceState = {
  data: null,
  count: {},
  isPending: false,
}

const selectState = (state: NotificationsSliceState) => state
const selectError = (state: NotificationsSliceState) => state.error
const selectIsPending = (state: NotificationsSliceState) => state.isPending
const selectTotalCount = (state: NotificationsSliceState) => state.count?.total
const selectUnreadCount = (state: NotificationsSliceState) =>
  state.count?.unread

const selectNotifications = createSelector(selectState, (state) => state.data)
const selectUnreadNotifications = createSelector(selectNotifications, (list) =>
  list?.filter((n) => n.status === 'UNREAD'),
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
    setError: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload
    },
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.data = [...(state.data || []), ...action.payload]
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      if (state.data) {
        state.data.push(action.payload)
      }
    },
    markNotification: (state, action: PayloadAction<MarkNotificationProps>) => {
      if (!state.data) return

      state.data = state.data.map((n) =>
        n.id === action.payload.id
          ? { ...n, status: action.payload.status }
          : n,
      )
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.count.total = action.payload
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.count.unread = action.payload
    },
  },
  selectors: {
    selectError,
    selectIsPending,
    selectTotalCount,
    selectUnreadCount,
    selectNotifications,
    selectUnreadNotifications,
  },
}).injectInto(rootReducer)
